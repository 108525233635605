import travelObject from '@/entities/travel/interface'
import { TFunction } from 'next-i18next'
import { buildTravelDatesLabel } from '@/lib/helpers/travel'

export const buildText = (travel: travelObject, t: TFunction) => {
  let text = ''

  // ship
  if (travel.accommodationAssignments.length) {
    text += `${travel.accommodationAssignments[0].accommodation.name} | `
  }

  // travel dates
  text += buildTravelDatesLabel(travel.pricing.travels, t)

  return text
}
