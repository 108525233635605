import styles from '@/components/elements/buttons/CardButton/CardButton.module.scss'
import Button from 'react-bootstrap/Button'
import Link from 'next/link'

interface Props {
  label: string
  href?: string
  targetBlank?: boolean
  className?: string
}

const CardButton = ({ label, href, targetBlank = false, className = '' }: Props) => {
  return (
    <div className={`${styles.root} ${className}`}>
      {href !== undefined ? (
        <Link href={href} passHref>
          <Button target={targetBlank ? '_blank' : undefined}>{label}</Button>
        </Link>
      ) : (
        <Button>{label}</Button>
      )}
    </div>
  )
}

export default CardButton
