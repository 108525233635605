interface Props {
  className?: string
}

const ContentDivider = ({ className }: Props) => {
  return (
    <>
      <div className={`divider ${className}`} />
      <style jsx>
        {`
          .divider {
            text-align: center;
            padding: 0;
            background-color: lightgray;
            height: 35px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
          }
        `}
      </style>
    </>
  )
}

export default ContentDivider
