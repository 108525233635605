import styles from './TravelDefaultTeaser.module.scss'
import { Card } from 'react-bootstrap'
import Link from 'next/link'
import travelObject from '@/entities/travel/interface'
import CloudinaryImage from '@/components/elements/media/CloudinaryImage'
import { buildTravelDatesLabel } from '@/lib/helpers/travel'
import { formatPrice } from '@/lib/helpers/helpers'
import { useTranslation } from 'next-i18next'
import TeaserFlag from '@/components/elements/TeaserFlag'

interface Props {
  travel: travelObject
}

const TravelDefaultTeaser = ({ travel }: Props) => {
  const { t } = useTranslation('common')

  return (
    <>
      <Link href={`/tour/${travel.seoSettings.urlSlug}`} passHref>
        <a className={styles.wrap}>
          <Card className={styles.card + ' an-up'}>
            <div className="img-ratio-16-9">
              <CloudinaryImage
                image={travel.mainImage}
                className={'card-img'}
                altDefault={travel.name}
                aspectRatio={1.777}
                width={527}
                responsive={false}
                placeholderClassName={'position-absolute'}
              />
            </div>
            {travel.label && <TeaserFlag text={travel.label.label} />}
            <Card.Body className={styles.body}>
              <Card.Title className={styles.title}>{travel.name}</Card.Title>
              <Card.Text as={'div'}>
                {travel.accommodationAssignments.length > 0 && (
                  <div className={styles.accommodation}>{travel.accommodationAssignments[0].accommodation.name}</div>
                )}
                <div className={styles.date} dangerouslySetInnerHTML={{ __html: buildTravelDatesLabel(travel.pricing.travels, t) }} />
                <div className={styles.price}>{`${t('booking.priceFrom')} ${formatPrice(travel.minPrice, travel.currency)}`}</div>
              </Card.Text>
            </Card.Body>
          </Card>
        </a>
      </Link>
    </>
  )
}

export default TravelDefaultTeaser
