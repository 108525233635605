import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import cmsTravelSelectionBlockObject from '@/entities/cmsTravelSelectionBlock/interface'
import TravelDefaultTeaser from '@/components/models/travel/teasers/TravelDefaultTeaser'
import { isTravelOnline } from '@/lib/helpers/travel'

const TravelSelectionBlock = ({ travels }: cmsTravelSelectionBlockObject) => {
  return (
    <div className={'bg-primary pt-4 pb-5'}>
      <Container className={'mb-5'}>
        <Row>
          {travels
            .filter((travel) => isTravelOnline(travel))
            .map((travel, index: number) => {
              return (
                <Col key={index} md={6} lg={4} xl={3} className={'mb-3'}>
                  <TravelDefaultTeaser travel={travel} key={index} />
                </Col>
              )
            })}
        </Row>
      </Container>
    </div>
  )
}

export default TravelSelectionBlock
